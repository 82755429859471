import { useTranslation } from "react-i18next";
import TextSection from "../../shared/TextSection";
import Reactions from "./Reactions";
import CardWrapper from "./CardWrapper";

interface Props {
	id: string;
	linkTo: string;
	image: string;
	title: string;
	description: string;
	reactions: boolean;
}

const ProjectCard = ({
	linkTo,
	image,
	title,
	description,
	reactions,
	id,
}: Props) => {
	return (
		<>
			<CardWrapper linkTo={linkTo} id={id}>
				<div className={`h-full w-full flex flex-col justify-start`}>
					<div className="mb-4">
						<img src={image} alt="project-logo" />
					</div>
					<div className="px-4 mb-4">
						<TextSection title={title}>{description}</TextSection>
						<div className="mb-4"></div>
						{reactions && <Reactions />}
					</div>
				</div>
			</CardWrapper>
		</>
	);
};

export default ProjectCard;
