import ProjectCard from "./ProjectCard";
import useGetCardsInfo from "../../hooks/useGetCardsInfo";
import { IProjectCard } from "../../interfaces";

const ProjectList = () => {
	const cards = useGetCardsInfo();
	return (
		<div className="flex md:gap-10 lg:gap-14 xl:gap-20 relative w-full max-w-[708px] rounded-xl">
			{cards.map((card: IProjectCard) => (
				<ProjectCard
					id={card.id}
					key={card.linkTo}
					linkTo={card.linkTo}
					image={card.image}
					title={card.title}
					description={card.description}
					reactions={card.reactions}
				/>
			))}
		</div>
	);
};

export default ProjectList;
