import { Modal } from "../../../shared/Modal";
import { useTranslation } from "react-i18next";
import DetailedViewTitle from "../../../shared/DetailedViewTitle";
import { useGetCurrentUser } from "../../../hooks/useGetCurrentUser";
import {
	setAuth,
	setOpenJoinProjectWindow,
	setOpenGuideWindow,
} from "../../../redux/api/userSlice";
import { useAppDispatch } from "../../../redux/hooks";

import parries from "../../../assets/detailedView/parries.svg";

interface IParryDetailedViewModal {
	isOpen: boolean;
	handleClose: () => void;
}

const ParryDetailedViewModal = ({
	isOpen,
	handleClose,
}: IParryDetailedViewModal) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useGetCurrentUser();

	return (
		<Modal
			isOpen={isOpen}
			handleClose={handleClose}
			modalContainerClassName={
				"max-w-[566px] relative p-[40px] rounded-xl overflow-visible"
			}
		>
			<div className="max-w-[342px] w-[100%] my-0 mx-auto overflow-auto flex items-center gap-[32px] flex-col">
				<DetailedViewTitle>
					{t("verification.viewModalTitle")}
				</DetailedViewTitle>
				<p className="max-w-[342px] font-Poppins font-medium">
					{t("verification.viewModalText")}
				</p>
				<div className="w-full">
					<button
						onClick={() => {
							if (!user) {
								dispatch(
									setAuth({
										openAuthModal: true,
										authStep: "basic",
										isOpenedFrom: "Project",
									})
								);
								dispatch(setOpenJoinProjectWindow(false));
								return;
							}
							dispatch(setOpenGuideWindow(true));
							dispatch(setOpenJoinProjectWindow(false));
						}}
						className="py-[12px]  bg-black rounded-[38px] text-white text-base flex items-center gap-[6px] justify-center hover:bg-primaryBlack transition-all w-full"
					>
						<img src={parries} alt="parries" />
						{t("ranking.detailedView.parry.buttonText")}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ParryDetailedViewModal;
