interface Props {
  children: React.ReactNode;
  title: string;
}

const TextSection = ({children, title}: Props) => {
  return (
    <div className="text-start">
      <div className="mb-4">
        <p className="text-2xl font-poppinsBold max-w-[270px]">{title}</p>
      </div>
      <div className="w-[270px] text-sm leading-5">{children}</div>
    </div>
  );
};

export default TextSection;
