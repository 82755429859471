import TextSection from "../../shared/TextSection";
import Wrapper from "../../shared/Wrapper";
import ProjectList from "./ProjectList";
import Header from "../header/Header";
import {useTranslation} from "react-i18next";
import MobileNoAccess from "../mobileNoAccess/MobileNoAccess";
import {useAppSelector} from "../../redux/hooks";

const Projects = () => {
  const {t} = useTranslation();
  const isMobile = useAppSelector((state) => state.elementsView.isMobile);

  return (
    <>
      <Header>
        <div className="flex w-1/4 items-center justify-center text-2xl">
          <p>{t("projects.title")}</p>
        </div>
      </Header>
      {isMobile ? (
        <MobileNoAccess />
      ) : (
        <Wrapper>
          <TextSection title={t("projects.column.title")}>
            <div className="flex flex-col gap-y-4">
              <p>{t("projects.column.text_1")}</p>
              <p>{t("projects.column.text_2")}</p>
              <p>{t("projects.column.text_3")}</p>
            </div>
          </TextSection>
          <ProjectList />
        </Wrapper>
      )}
    </>
  );
};

export default Projects;
