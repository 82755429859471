import { useAppSelector } from "../redux/hooks";

const useIsJoinedToProject = () => {
	const currentOpenedProject = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);
	const joinedProjects = useAppSelector(
		(state) => state.user.isJoinedToProjects
	);

	return currentOpenedProject !== null
		? joinedProjects.includes(currentOpenedProject)
		: false;
};

export default useIsJoinedToProject;
