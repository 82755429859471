import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";

import VerifyUserModal from "../verification/VerifyUserModal";
import { ProfileModal } from "../profile/ProfileModal";

import useClickOutside from "../../hooks/useClickOutside";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import useIsJoinedToProject from "../../hooks/useIsJoinedToProject";

import { setVerifyWindow } from "../../redux/api/userSlice";

import { isProjectRoute } from "../../utils/checkRoute";

import { avatars } from "../../assets/avatar";
import { dropdownIcon } from "../../assets/shared";

const arrayOfAvatars = Object.values(avatars);
const randomIndex =
	Number(localStorage.getItem("avatarRandomIndex")) ||
	Math.floor(Math.random() * arrayOfAvatars.length);

export const AvatarDropdown = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const hasUserClaimedProjectTokens = useIsJoinedToProject();
	const { user } = useGetCurrentUser();
	const userID = user?.uid;
	const { userName, about, interests, spot, tokens } = useUserProfileInfo(
		`${userID}`
	);
	const { element, clickOutSide, isVisible } =
		useClickOutside<HTMLDivElement>();

	const userTokens = useAppSelector((state) => state.user.tokens);
	const hasUserVerified = useAppSelector((state) => state.user.hasUserVerified);
	const { isOpen, openedFrom } = useAppSelector(
		(state) => state.user.verifyWindow
	);

	const randomAvatar = arrayOfAvatars[randomIndex] || arrayOfAvatars[0];

	const [showTokens, setShowTokens] = useState(false);
	const [newTokens, setNewTokens] = useState<null | number>(tokens);
	const [name, setName] = useState<string>("");
	const [photo, setPhoto] = useState(randomAvatar);
	const [maxAvailableTokens, setMaxAvailableTokens] = useState(0);

	useEffect(() => {
		user && setName(`${user.displayName}`);

		if (user?.photoURL) {
			user && setPhoto(`${user.photoURL}`);
		}
		if (tokens || tokens === 0) {
			user && setNewTokens(userTokens);
		}
	}, [user?.photoURL, userTokens, tokens, user]);

	useEffect(() => {
		if (isProjectRoute(location.pathname)) {
			setShowTokens(true);
		}
	}, [location]);

	useEffect(() => {
		if (!Number(localStorage.getItem("avatarRandomIndex"))) {
			localStorage.setItem("avatarRandomIndex", String(randomIndex));
		}
	}, []);

	useEffect(() => {
		const isOpen = sessionStorage.getItem("isModalOpen");
		if (isOpen === "true") {
			clickOutSide(true);
			sessionStorage.removeItem("isModalOpen");
		}
	}, [clickOutSide]);

	useEffect(() => {
		const maxTokens =
			hasUserVerified && hasUserClaimedProjectTokens
				? 500
				: hasUserVerified || hasUserClaimedProjectTokens
				? 250
				: 0;
		setMaxAvailableTokens(maxTokens);
	}, [hasUserVerified, hasUserClaimedProjectTokens]);

	const closeVerifyWindow = () => {
		dispatch(setVerifyWindow({ isOpen: false, openedFrom: "profile" }));
	};

	const handleAvatarDropdownClick = () => {
		clickOutSide(!isVisible);
	};

	return (
		<div ref={element} className="relative">
			<button
				onClick={handleAvatarDropdownClick}
				type="button"
				className="flex justify-between items-center border-[2px] rounded-[50px] border-black p-1 gap-[8px]"
			>
				<div className="flex h-10 overflow-hidden rounded-full shrink-0">
					<img
						src={photo}
						alt="avatar"
						className="h-10 w-10 object-cover shrink-0"
					/>
				</div>
				{showTokens && newTokens !== null && newTokens >= 0 && (
					<div className="flex items-center">
						<div className="text-[24px] poppins">{newTokens}</div>
						<span className="text-[20px] poppins text-[#94979C]">/</span>
						<div className="text-[20px] poppins text-[#94979C]">
							{maxAvailableTokens}
						</div>
					</div>
				)}
				<img
					src={dropdownIcon}
					alt="open"
					className={`h-9 w-9 ${isVisible && "rotate-180"}`}
				/>
			</button>

			{isVisible && (
				<div className="absolute right-0 top-16">
					<ProfileModal
						randomAvatar={photo}
						displayName={`${name}`}
						userName={`${userName}`}
						aboutUser={`${about}`}
						userInterests={interests}
						favoriteSpot={spot}
						handleAvatarDropdownClick={handleAvatarDropdownClick}
						isUserVerified={hasUserVerified}
					/>
				</div>
			)}
			<VerifyUserModal
				isOpen={isOpen}
				openedFrom={openedFrom}
				closeVerifyWindow={closeVerifyWindow}
			/>
		</div>
	);
};
