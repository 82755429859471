import { goodSpot, danger, useful } from "../../assets/map/index";

const Reactions = () => {
  return (
    <div className="flex gap-4">
      <div>
        <img src={goodSpot} alt="good-spot" />
      </div>
      <div>
        <img src={danger} alt="danger" />
      </div>
      <div>
        <img src={useful} alt="useful" />
      </div>
    </div>
  );
};

export default Reactions;
