import {
	DocumentData,
	doc,
	updateDoc,
	collection,
	addDoc,
	deleteDoc,
} from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebase/firebase";
import { useAppDispatch } from "../redux/hooks";
import { setTokens } from "../redux/api/userSlice";
import { getSpots } from "../firebase/getSpots";
import { setSpots } from "../redux/api/spotsSlice";
import { uploadImageToStorage } from "../firebase/utils/uploadImage";
import { setSpotCardView, changeSection } from "../redux/api/elementsViewSlice";
import { getDayMonthYear, getHoursAndMinutes } from "../utils/getTime";
import { setPlaceCreationStatus } from "../redux/api/locationSlice";

interface IHandleAddSpotSubmit {
	userID: string | undefined;
	setIsLoading: (arg: boolean) => void;
	placeData: DocumentData;
	tokens: number | undefined;
	userCurrTokens?: number | null;
	placeFile: File | null;
	handleClose: () => void;
	onSubmitHandler: (arg: DocumentData) => void;
	selectedCard?: DocumentData | null;
}

interface IHandleDeleteSpot {
	userID: string | undefined;
	placeData: DocumentData;
	tokens: number | undefined;
	handleClose: () => void;
}

const useHandleAddSpot = () => {
	const dispatch = useAppDispatch();

	const handleSubmit = async ({
		userID,
		setIsLoading,
		placeData,
		tokens,
		userCurrTokens,
		placeFile,
		handleClose,
		onSubmitHandler,
	}: IHandleAddSpotSubmit) => {
		if (!userID) return;
		try {
			setIsLoading(true);
			const auth = getAuth();
			const currentUser = auth.currentUser;
			const userRef = doc(db, "users", userID);
			const submitObj: DocumentData = structuredClone(placeData);

			if (
				submitObj.votes &&
				submitObj.votes[userID] &&
				typeof tokens !== "undefined" &&
				userCurrTokens
			) {
				// const currTokens = tokens - submitObj.votes[userID].tokens;

				if (currentUser && typeof tokens !== "undefined") {
					await updateDoc(userRef, { tokens: userCurrTokens });
				}
				dispatch(setTokens(userCurrTokens));

				if (submitObj.secondaryTags) {
					const type = submitObj.secondaryTags.slice(0, 1).join("");
					const secondaryTypes = submitObj.secondaryTags.slice(1);

					submitObj.secondaryTags = secondaryTypes;
					submitObj.type = type;
				}
				submitObj.spotColor = submitObj.color;
				submitObj.date = getDayMonthYear();
				submitObj.time = getHoursAndMinutes();

				const spotsCollectionRef = collection(db, "spots");
				const docRef = await addDoc(spotsCollectionRef, submitObj);

				const newSpotId = docRef.id;

				if (placeFile) {
					const url = await uploadImageToStorage(
						placeFile,
						userID,
						`/spotsPics${newSpotId}`
					);
					submitObj.picture = url;
				}

				await updateDoc(docRef, { id: newSpotId, picture: submitObj.picture });

				const spots = await getSpots();

				if (!spots) return;

				dispatch(setSpots(spots));
				const selectedSpot = spots.find((spot) => spot.id === newSpotId);

				handleClose();
				dispatch(setPlaceCreationStatus("created"));
				setTimeout(() => {
					if (!selectedSpot) return;
					onSubmitHandler(selectedSpot);
				}, 2000);
				setIsLoading(false);
			} else {
				console.error("Tokens or votes data is missing.");
				setIsLoading(false);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleUpdate = async ({
		userID,
		setIsLoading,
		placeData,
		tokens,
		selectedCard,
		placeFile,
		onSubmitHandler,
		handleClose,
	}: IHandleAddSpotSubmit) => {
		if (!userID) return;
		try {
			setIsLoading(true);
			const auth = getAuth();
			const currentUser = auth.currentUser;
			const userRef = doc(db, "users", userID);
			const updateObj: DocumentData = structuredClone(placeData);

			if (
				updateObj.votes &&
				updateObj.votes[userID] &&
				typeof tokens !== "undefined" &&
				selectedCard
			) {
				const previousTokens = selectedCard.votes[userID].tokens;

				const currTokens = updateObj.votes[userID].tokens;

				if (previousTokens !== currTokens) {
					const updateTokens =
						tokens - updateObj.votes[userID].tokens + previousTokens;

					if (currentUser && typeof tokens !== "undefined") {
						await updateDoc(userRef, { tokens: updateTokens });
					}
					dispatch(setTokens(updateTokens));
				}

				if (placeFile) {
					const url = await uploadImageToStorage(
						placeFile,
						userID,
						"/spotsPics"
					);
					updateObj.picture = url;
				}

				if (updateObj.secondaryTags) {
					const type = updateObj.secondaryTags.slice(0, 1).join("");
					const secondaryTypes = updateObj.secondaryTags.slice(1);

					updateObj.secondaryTags = secondaryTypes;
					updateObj.type = type;
				}
				updateObj.spotColor = updateObj.color;

				const spotDocRef = doc(db, "spots", placeData.id);
				await updateDoc(spotDocRef, updateObj);

				const spots = await getSpots();

				if (!spots) return;

				dispatch(setSpots(spots));
				const selectedSpot = spots.find((spot) => spot.id === placeData.id);
				if (!selectedSpot) return;
				onSubmitHandler(selectedSpot);

				handleClose();
			} else {
				console.error("Tokens or votes data is missing.");
			}
			setIsLoading(false);
		} catch (err) {
			console.log(err);
			setIsLoading(false);
		}
	};

	const handleDelete = async ({
		userID,
		placeData,
		tokens,
		handleClose,
	}: IHandleDeleteSpot) => {
		if (!userID || !placeData.id) return;
		try {
			const spotDocRef = doc(db, "spots", placeData.id);

			await deleteDoc(spotDocRef);
			const returnTokens = tokens + placeData.votes[userID].tokens;
			dispatch(setTokens(returnTokens));
			const spots = await getSpots();

			if (!spots) return;

			dispatch(setSpots(spots));
			dispatch(setSpotCardView(false));
			dispatch(changeSection("Ranking"));
			handleClose();
		} catch (err) {
			console.log(err);
		}
	};

	return { handleSubmit, handleUpdate, handleDelete };
};

export default useHandleAddSpot;
