import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import claimVerificationTokensEn from "../../assets/shared/parryClaimVerification.png";
import claimVerificationTokensDe from "../../assets/shared/parryClaimVerificationDe.png";
import claimTokensIcon from "../../assets/detailedView/claimTokensIcon.svg";

import DetailedViewTitle from "../../shared/DetailedViewTitle";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import useUserProfileInfo from "../../hooks/useUserProfileInfo";
import { useAppDispatch } from "../../redux/hooks";
import { setTokens } from "../../redux/api/userSlice";
import Lottie from "react-lottie";
import claimTokensAnimation from "../../assets/animation/tokensCompleted.json";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

interface IClaimVerificationTokens {
	closeVerifyWindow: () => void;
}

const ClaimVerificationTokens = ({
	closeVerifyWindow,
}: IClaimVerificationTokens) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();

	const currentLanguage = i18n.language;

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: claimTokensAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const [startAnimation, setStartAnimation] = useState(false);
	const { user } = useGetCurrentUser();
	const userId = user?.uid;
	const { tokens } = useUserProfileInfo(`${userId}`);

	const claimTokens = async () => {
		if (!userId) return;
		try {
			const userRef = doc(db, "users", userId);
			const claimedTokens = tokens + 250;

			if (typeof tokens !== "undefined") {
				await updateDoc(userRef, {
					tokens: claimedTokens,
				});
				dispatch(setTokens(claimedTokens));
			}

			setStartAnimation(true);

			setTimeout(() => {
				closeVerifyWindow();
			}, 2000);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<div className="max-w-[342px] w-[100%] my-0 mx-auto relative">
			<div className={`mb-[16px] ${startAnimation && "invisible"}`}>
				<DetailedViewTitle>
					{t("verification.claimTokensTitle")}
				</DetailedViewTitle>
			</div>

			<p
				className={`text-[#676a70] font-medium mb-[68px]  ${
					startAnimation && "invisible"
				}`}
			>
				{t("verification.claimTokensDescription")}
			</p>

			<div className="w-full flex justify-center mb-[45px] ">
				<div className="w-[212px] h-[180px] relative overflow-hidden flex items-center">
					<img
						className="overflow-hidden"
						src={
							currentLanguage === "en"
								? claimVerificationTokensEn
								: claimVerificationTokensDe
						}
						alt="Claim tokens pary"
					/>
				</div>
			</div>

			<div className={`w-full  ${startAnimation && "invisible"}`}>
				<button
					onClick={claimTokens}
					className="bg-black py-[16px] text-white rounded-[50px] w-full flex text-center items-center justify-center px-[30px] gap-[8px]"
				>
					<img src={claimTokensIcon} alt="tokens icon" />{" "}
					<span>{t("verification.claimTokensCollect")}</span>
				</button>
			</div>
			{startAnimation && (
				<div className="absolute inset-0 flex items-center justify-center">
					<Lottie options={defaultOptions} />
				</div>
			)}
		</div>
	);
};

export default ClaimVerificationTokens;
