import { useEffect, useRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DetailedViewTitle from "../../../../shared/DetailedViewTitle";
import { useGetCurrentUser } from "../../../../hooks/useGetCurrentUser";
import { Link } from "react-router-dom";
import { paths } from "../../../../constants";
import { DocumentData } from "firebase/firestore";
import useHandleVotesChange from "../../../../firebase/utils/handleVotes";
import {
	updateVoteInfo,
	createVoteInfo,
} from "../../../../utils/createVoteInfo";
import VoteSystem from "../../../../shared/VoteSystem";
import ViewModModal from "../../../auth/ViewModModal";
import { useUpdateSpotOnFirebase } from "../../../../firebase/utils/handleVotes";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import useDebounce from "../../../../hooks/useDebounceRef";
import useUserProfileInfo from "../../../../hooks/useUserProfileInfo";
import useRestrictions from "../../../../hooks/useRestrictions";
import {
	setVerifyWindow,
	setOpenJoinProjectWindow,
} from "../../../../redux/api/userSlice";
import VotingInfoModal from "./VotingInfoModal";
import useIsJoinedToProject from "../../../../hooks/useIsJoinedToProject";

interface Props {
	selectedCard: DocumentData;
	voters: number;
}

interface IVotes {
	date: number;
	submitted_by: string;
	tokens: number;
}

const DistrictInfo = ({ selectedCard, voters }: Props) => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation();
	const { user } = useGetCurrentUser();
	const userID = user?.uid;
	const {
		isViewMod,
		hangleSignIn,
		hangleLogIn,
		handleViewModClose,
		handleViewModOpen,
	} = useRestrictions();
	const { addVotesInfo } = useHandleVotesChange();
	const { updateSpotOnFirebase } = useUpdateSpotOnFirebase();
	const { tokens } = useUserProfileInfo(`${userID}`);

	const [qvModalOpen, setQvModalOpen] = useState<boolean>(false);

	const updatedCard = useAppSelector((state) => state.spots.selectedCard);
	const userTokens = useAppSelector((state) => state.user.tokens);
	const hasUserVerified = useAppSelector((state) => state.user.hasUserVerified);
	const hasUserJoinedProject = useIsJoinedToProject();

	const updatedCardRef = useRef(updatedCard);
	const updatedUserTokens = useRef(userTokens);

	useEffect(() => {
		updatedCardRef.current = updatedCard;
		updatedUserTokens.current = userTokens;
	}, [updatedCard, userTokens]);

	const setToFirebase = useCallback(() => {
		if (updatedUserTokens.current) {
			updateSpotOnFirebase(
				selectedCard.id,
				userID!,
				updatedUserTokens.current,
				updatedCardRef.current
			);
		}
	}, [selectedCard.id, userID, userTokens, updatedCard, updateSpotOnFirebase]);

	const debouncedSetToFirebase = useDebounce(setToFirebase, 500);

	const {
		district,
		total_votes: totalVotes,
		color,
		level,
		percantage,
		districtRank,
		id,
		category,
	} = selectedCard;
	const voteInfo: IVotes | undefined = selectedCard.votes[userID as string];

	const votersNum = voters > 1 ? "mult" : "single";
	const votesNum = totalVotes > 1 ? "mult" : "single";

	const handleQvModalClose = () => {
		setQvModalOpen(false);
	};

	const handleChange = async (direction: string) => {
		if (!user) {
			handleViewModOpen();
			return;
		}

		switch (direction) {
			case "increase":
				if (!hasUserJoinedProject) {
					dispatch(setOpenJoinProjectWindow(true));
					break;
				}
				if (voteInfo === undefined) {
					const createVote = createVoteInfo(userID);
					if (createVote && userID && userTokens)
						addVotesInfo(
							id,
							userID,
							createVote,
							selectedCard,
							userTokens,
							"increase"
						);
				} else {
					if (!hasUserVerified) {
						dispatch(setVerifyWindow({ isOpen: true, openedFrom: "voting" }));
						break;
					}
					const updateVote = updateVoteInfo(
						selectedCard.votes,
						userID,
						tokens || 0,
						true,
						userTokens || 0
					);

					if (updateVote === null) break;

					if (updateVote && userID && userTokens && selectedCard && id) {
						await addVotesInfo(
							id,
							userID,
							updateVote,
							selectedCard,
							userTokens,
							"increase"
						);
					}
				}
				debouncedSetToFirebase();
				break;
			case "decrease":
				if (!hasUserJoinedProject) {
					dispatch(setOpenJoinProjectWindow(true));
					break;
				}
				if (selectedCard.total_votes === 1) {
					break;
				}
				const decreaseVote = updateVoteInfo(
					selectedCard.votes,
					userID,
					tokens || 0,
					false
				);

				if (
					decreaseVote === null &&
					userID &&
					selectedCard.votes[userID] &&
					userTokens !== null
				) {
					addVotesInfo(
						id,
						userID,
						decreaseVote,
						selectedCard,
						userTokens,
						"decrease"
					);
				}

				if (decreaseVote && userID && userTokens !== null)
					addVotesInfo(
						id,
						userID,
						decreaseVote,
						selectedCard,
						userTokens,
						"decrease"
					);
				debouncedSetToFirebase();
		}
	};

	return (
		<div className="p-6 w-full border-2 border-[#E8E8E8] rounded-xl ">
			<div className="flex justify-between gap-4 mb-[16px]">
				<div className="w-[54%]">
					<div className="flex flex-col gap-[24px]">
						<div className="flex items-center gap-[12px]">
							<h3 className="text-[22px] font-poppinsBold">
								{t("ranking.detailedView.districtInfo.title")}
							</h3>
							<button onClick={() => setQvModalOpen(true)}>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13.5 7.58535C13.5 8.41378 12.8284 9.08535 12 9.08535C11.1716 9.08535 10.5 8.41378 10.5 7.58535C10.5 6.75693 11.1716 6.08535 12 6.08535C12.8284 6.08535 13.5 6.75693 13.5 7.58535Z"
										fill="#6AA0F1"
									/>
									<path
										d="M11 11C10.4477 11 10 11.4477 10 12C10 12.5523 10.4477 13 11 13H12V17C12 17.5523 12.4477 18 13 18C13.5523 18 14 17.5523 14 17V13C14 11.8954 13.1046 11 12 11H11Z"
										fill="#6AA0F1"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
										fill="#6AA0F1"
									/>
								</svg>
							</button>
						</div>
						<div className="text-[14px] font-poppins flex flex-col gap-[4px]">
							<div>
								<span>
									{t("ranking.detailedView.districtInfo.rank")}: {districtRank}
								</span>
								<span> in {district}</span>
							</div>
							<div className="flex items-center gap-[5px]">
								<span>
									{t(`ranking.detailedView.districtInfo.priority`)}:{" "}
									{t(`ranking.detailedView.districtInfo.${level}`)}
								</span>
								<span
									style={{ backgroundColor: color }}
									className={`block w-[11px] h-[11px] rounded-full`}
								></span>
							</div>
							<div>
								<span className="">
									{t(`ranking.detailedView.districtInfo.voters-${votersNum}`)}:{" "}
									{voters}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="w-[46%] flex">
					<div className="">
						<VoteSystem
							color={color}
							percantage={percantage}
							voteInfo={voteInfo}
							votes={totalVotes}
							handleChange={handleChange}
							category={category}
						/>
					</div>
				</div>
			</div>
			<ViewModModal
				isOpen={isViewMod}
				handleClose={handleViewModClose}
				hangleSignIn={hangleSignIn}
				hangleLogIn={hangleLogIn}
			/>
			<VotingInfoModal isOpen={qvModalOpen} handleClose={handleQvModalClose} />
		</div>
	);
};

export default DistrictInfo;
