import { useTranslation } from "react-i18next";
import { addProjectImage, projectImage } from "../assets/shared";
import { paths } from "../constants";

const useGetCardsInfo = () => {
	const { t } = useTranslation();
	const cards = [
		{
			id: "qVd1pmkv43a8snIcz7Ro", //temporary hardcoded id from firebase
			linkTo: paths.project("cycling-spots"),
			image: projectImage,
			name: "cycling-spots",
			title: t("shared.cyclingSpots"),
			description: t("projects.card.description"),
			reactions: true,
		},
		{
			id: "newProject",
			linkTo: paths.newProject,
			image: addProjectImage,
			name: "new-project",
			title: t("projects.addProject.title"),
			description: t("projects.addProject.description"),
			reactions: false,
		},
	];
	return cards;
};

export default useGetCardsInfo;
