import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface initialState {
	selectedProjectLink: null | string;
	selectedProjectId: null | string;
}

const initialState: initialState = {
	selectedProjectLink: null,
	selectedProjectId: null,
};

export const projectCardsSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setSelectedProjectLink: (state, action: PayloadAction<string | null>) => {
			state.selectedProjectLink = action.payload;
		},
		setSelectedProjectId: (state, action: PayloadAction<string | null>) => {
			state.selectedProjectId = action.payload;
		},
	},
});

export const { setSelectedProjectLink, setSelectedProjectId } =
	projectCardsSlice.actions;
export default projectCardsSlice.reducer;
