import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useGetPath from "../../../hooks/useGetPath";
import { IProjectCard } from "../../../interfaces";
import { setSelectedProjectLink } from "../../../redux/api/projectCardsSlice";
import { useAppDispatch } from "../../../redux/hooks";
import ProjectCard from "../../projects/ProjectCard";
import useGetCardsInfo from "../../../hooks/useGetCardsInfo";

const ProjectsModal = () => {
	const { t } = useTranslation();

	const pathName = useGetPath();
	const dispatch = useAppDispatch();
	useEffect(() => {
		dispatch(setSelectedProjectLink(pathName));
	});

	const cards = useGetCardsInfo();

	return (
		<div className="max-w-[1057px] h-[568px] bg-white rounded-2xl px-4 pt-6 ">
			<div className="border-b border-[#C8D2C2] ml-2 mr-10 mb-2">
				<p className="font-poppinsBold text-2xl mb-1">
					{t("projects-modal.title")}
				</p>
				<p className="text-[16px] mb-4">{t("projects-modal.text")}</p>
			</div>
			<div className="h-[456px] w-full overflow-hidden">
				<div className="flex gap-x-4 gap-y-6 w-full justify-start flex-wrap overflow-y-auto h-full scroll-transparent scroll-thumb-green scroll-wide py-3 px-2">
					{cards.map((card: IProjectCard) => (
						<ProjectCard
							id={card.id}
							key={card.linkTo}
							linkTo={card.linkTo}
							image={card.image}
							title={card.title}
							description={card.description}
							reactions={card.reactions}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProjectsModal;
