import { useState, useEffect } from "react";

import { Modal } from "../../shared/Modal";

import { useTranslation } from "react-i18next";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase/firebase";

import { VerificationStep } from "../../types";
import ChooseMethodWindow from "./ChooseMethodWindow";
import VerificationAnnounce from "./VerificationAnnounce";
import RequestSms from "./RequestSms";
import ConfirmSms from "./ConfirmSms";
import ClaimVerificationTokens from "./ClaimVerificationTokens";

interface IVerifyUserModal {
	isOpen: boolean;
	closeVerifyWindow: () => void;
	openedFrom: "profile" | "voting";
}

const VerifyUserModal = ({
	isOpen,
	closeVerifyWindow,
	openedFrom,
}: IVerifyUserModal) => {
	const { t } = useTranslation();

	const [confirmationResult, setConfirmationResult] = useState(null);
	const [fullPhoneNumber, setFullPhoneNumber] = useState("");

	const [verificationStep, setVerificationStep] =
		useState<VerificationStep>("basic");

	const closeVerifyWindowModified = () => {
		closeVerifyWindow();
		setVerificationStep("basic");
	};

	const handleGoToStep = (newAuthStep: VerificationStep) =>
		setVerificationStep(newAuthStep);

	const basicModal = (
		<VerificationAnnounce
			closeVerifyWindow={closeVerifyWindowModified}
			handleGoToStep={handleGoToStep}
			openedFrom={openedFrom}
		/>
	);

	const variantsLayout: Record<VerificationStep, JSX.Element> = {
		basic: basicModal,
		chooseMethod: <ChooseMethodWindow handleGoToStep={handleGoToStep} />,
		requestSMS: (
			<RequestSms
				handleGoToStep={handleGoToStep}
				closeVerifyWindow={closeVerifyWindowModified}
				setConfirmationResult={setConfirmationResult}
				setFullPhoneNumber={setFullPhoneNumber}
			/>
		),
		confirmSMS: (
			<ConfirmSms
				handleGoToStep={handleGoToStep}
				confirmationResult={confirmationResult}
				verifiedPhoneNumber={fullPhoneNumber}
				setConfirmationResult={setConfirmationResult}
			/>
		),
		claimVerificationTokens: (
			<ClaimVerificationTokens closeVerifyWindow={closeVerifyWindowModified} />
		),
	};

	useEffect(() => {
		setUpRecaptcha();

		// return () => {
		// 	window.recaptchaVerifier.reset();
		// 	window.recaptchaVerifier.clear();
		// 	window.recaptchaVerifier = null;
		// };
	}, []);

	const setUpRecaptcha = () => {
		window.recaptchaVerifier = new RecaptchaVerifier(
			"recaptcha-container",
			{
				size: "invisible",
				callback: (response: any) => {},
			},
			auth
		);
	};

	return (
		<>
			<div id="recaptcha-container"></div>
			<Modal
				isOpen={isOpen}
				handleClose={() => closeVerifyWindowModified()}
				modalContainerClassName={`max-w-[622px] relative pb-[45px] px-[40px] pt-[70px] rounded-xl ${
					verificationStep === "basic" ? "bg-[#3A85F4]" : "bg-white"
				} overflow-auto`}
				authStep={verificationStep}
			>
				<>{variantsLayout[verificationStep]}</>
			</Modal>
		</>
	);
};

export default VerifyUserModal;
