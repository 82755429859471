import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { useEffect, useState, memo, useRef } from "react";
import { useTranslation } from "react-i18next";
import BurgerMenu from "./BurgerMenu";
import { AvatarDropdown } from "./AvatarDropdown";
import { WelcomeBanner } from "../auth/WelcomeBanner";
import TokenGuideModal from "../../shared/TokenGuideModal";
import { AuthModal } from "../auth/AuthModal";

import {
	getAuth,
	isSignInWithEmailLink,
	signInWithEmailLink,
	signInWithEmailAndPassword,
} from "firebase/auth";

import useScrollToTop from "../../hooks/useScrollToTop";
import useClickOutside from "../../hooks/useClickOutside";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import useGetCardsInfo from "../../hooks/useGetCardsInfo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAuth, setOpenGuideWindow } from "../../redux/api/userSlice";
import { setSelectedProjectId } from "../../redux/api/projectCardsSlice";
import { isProjectRoute } from "../../utils/checkRoute";
import { setCookie } from "../../utils/cookies";

import { paths } from "../../constants";
import { IProjectCard } from "../../interfaces";

import { ReactComponent as Burger } from "../../assets/shared/burger.svg";
import { ReactComponent as CloseMenu } from "../../assets/burgerMenu/close.svg";
import { logo } from "../../assets/shared";
import Loader from "../../shared/Loader";

interface Props {
	children?: React.ReactNode;
	disableHide?: boolean;
	onClickLogin?: () => void;
}

const Header = memo(
	({ children, disableHide = false, onClickLogin }: Props) => {
		const { t } = useTranslation();
		const { initializing, user } = useGetCurrentUser();
		const navigate = useNavigate();
		const scrollTop = useScrollToTop();
		const location = useLocation();
		const dispatch = useAppDispatch();
		const [searchParams] = useSearchParams();
		const cards: IProjectCard[] = useGetCardsInfo();
		const { element, isVisible, clickOutSide } =
			useClickOutside<HTMLDivElement>("burger-button");

		const { openAuthModal = false, isOpenedFrom } = useAppSelector(
			(state) => state.user.auth
		);
		const guideOpen = useAppSelector((state) => state.user.openGuideWindow);

		const [scrollPosition, setScrollPosition] = useState(0);
		const [showHeader, setShowHeader] = useState(true);
		const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);

		const emailUrl = useRef<string | undefined>();

		const isMobileDevice = /iPhone|iPad|iPod|Android|Mobile/i.test(
			navigator.userAgent
		);

		const isFirstLogin = localStorage.getItem("first_login");
		const isUserNameConfirmed = localStorage.getItem("isUserNameConfirmed");

		const handleGuideClose = () => {
			dispatch(setOpenGuideWindow(false));
		};

		const dropdownLocations = [paths.startPage, paths.projects];
		const isAllowedRoute = dropdownLocations.some(
			(route) =>
				route === location.pathname || isProjectRoute(location.pathname)
		);

		useEffect(() => {
			if (isFirstLogin) {
				setShowWelcomeBanner(true);
			}
		}, [isFirstLogin]);

		useEffect(() => {
			const handleScroll = () => {
				const currentPosition = window.scrollY;
				if (currentPosition < 500) return;
				setShowHeader(currentPosition < scrollPosition);
				setScrollPosition(currentPosition);
			};
			window.addEventListener("scroll", handleScroll);
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}, [scrollPosition]);

		useEffect(() => {
			const continueUrl = searchParams.get("continueUrl");
			const isRankingEnabled = isProjectRoute(location.pathname);
			const auth = getAuth();

			cards.forEach((item) => {
				if (location.pathname.includes(item.name)) {
					dispatch(setSelectedProjectId(item.id));
				}
			});

			if (continueUrl && continueUrl.includes("?token=")) {
				const [_, token] = continueUrl.split("?token=");
				emailUrl.current = continueUrl;
				const email = localStorage.getItem("emailForSignIn");
				const password = localStorage.getItem("userPassword");

				setCookie("token", token);
				const regex = /\/project\/([a-zA-Z0-9-]+)/; //check if the url has "project"
				const match = continueUrl.match(regex);

				if (email && password) {
					signInWithEmailAndPassword(auth, email, password); // can be bug with async
				}

				if (match) {
					const projectName = match[1];
					navigate(paths.project(projectName));
				} else if (!isRankingEnabled) {
					navigate(paths.projects);
				}

				setShowWelcomeBanner(true);
				localStorage.setItem("isUserNameConfirmed", "true");
				localStorage.removeItem("emailForSignIn");
				localStorage.removeItem("userPassword");
				return;
			}

			const handleAuthCheck = async () => {
				const email = localStorage.getItem("emailForSignIn") || "";

				try {
					const isRedirected = isSignInWithEmailLink(
						auth,
						window.location.href
					);

					const regex = /\/project\/([a-zA-Z0-9-]+)/; //check if the url has "project"
					const match = continueUrl?.match(regex);

					if (isRedirected) {
						await signInWithEmailLink(auth, email, window.location.href);
						if (match) {
							const projectName = match[1];
							navigate(paths.project(projectName));
						} else if (!isRankingEnabled) {
							navigate(paths.projects);
						}
					}

					localStorage.removeItem("emailForSignIn");
				} catch (error) {
					console.log("error", error);
				}
			};

			handleAuthCheck();
		}, [navigate, searchParams]);

		useEffect(() => {
			if (
				!initializing &&
				user &&
				isAllowedRoute &&
				!openAuthModal &&
				!isUserNameConfirmed &&
				isFirstLogin === "true"
			) {
				const auth = getAuth();
				const user = auth.currentUser;
				if (user) {
					console.log("here");
					user.delete();
				}
			}
		}, [
			isUserNameConfirmed,
			initializing,
			user,
			isAllowedRoute,
			openAuthModal,
			isFirstLogin,
		]);

		return (
			<div
				className={`w-full transition-all duration-300 fixed top-0 left-0 flex bg-white z-50  ${
					!disableHide && !showHeader && !isVisible ? "-translate-y-full" : ""
				}`}
			>
				<div className="flex w-full h-[130px] px-10 lg:px-[80px] justify-between">
					<div className="flex items-center justify-between w-1/2 lg:w-1/3">
						<Link to={paths.startPage} onClick={scrollTop}>
							<img className="h-10 w-[106px]" src={logo} alt="logo" />
						</Link>
					</div>
					<div className="justify-center hidden w-1/3 lg:flex">{children}</div>
					<div className="relative flex items-center justify-end  w-1/2 lg:w-1/3">
						<div className="flex flex-row-reverse justify-between items-center gap-12">
							<button onClick={() => clickOutSide(!isVisible)}>
								{isVisible ? <CloseMenu className="w-[30px]" /> : <Burger />}
							</button>
							{initializing && !isMobileDevice && (
								<Loader
									noAbsolute
									parrentClassName="bg-transparent h-7 w-7"
									childClassName="text-black h-[100%] w-[100%] border-4"
								/>
							)}
							{!initializing && !user && !isMobileDevice && (
								<button
									onClick={() =>
										dispatch(
											setAuth({
												openAuthModal: true,
												authStep: "basic",
											})
										)
									}
									className="hidden xs:block font-bold text-lg shrink-0"
								>
									{t("header.login")}
								</button>
							)}
							{!initializing && user && isAllowedRoute && !openAuthModal && (
								<AvatarDropdown />
							)}
						</div>
						<div ref={element}>
							<BurgerMenu isVisible={isVisible} />
						</div>
					</div>
				</div>
				<AuthModal
					openAuthModal={openAuthModal}
					handleClose={() =>
						dispatch(
							setAuth({
								openAuthModal: false,
								authStep: "basic",
							})
						)
					}
				/>
				{((user && isFirstLogin === "true" && isUserNameConfirmed === "true") ||
					(isOpenedFrom === "Project" &&
						user &&
						isUserNameConfirmed === "true")) && (
					<WelcomeBanner
						isOpen={showWelcomeBanner}
						isOpenedFrom={isOpenedFrom!}
						setIsOpen={setShowWelcomeBanner}
						handleClose={() => {
							if (!isProjectRoute(location.pathname)) navigate(paths.projects);

							dispatch(
								setAuth({
									openAuthModal: false,
									authStep: "basic",
									isOpenedFrom: null,
								})
							);
							localStorage.removeItem("first_login");
							setShowWelcomeBanner(false);
						}}
						user={user}
					/>
				)}
				<TokenGuideModal isOpen={guideOpen} handleClose={handleGuideClose} />
			</div>
		);
	}
);

export default Header;
