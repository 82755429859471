interface Props {
  children: React.ReactNode;
}

const Wrapper = ({ children }: Props) => {
  return (
    <div className="w-screen flex flex-col 2xl:items-center items-start">
      <div className=" max-w-[1440px] pl-[80px] pt-[180px] pr-16 flex gap-[160px]">
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
