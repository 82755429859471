import { User } from "@firebase/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { boolean } from "yup";
import { AuthStep } from "../../types";

interface AuthInfo {
	openAuthModal?: boolean;
	authStep?: AuthStep;
	isOpenedFrom?: null | "Project";
}

interface IVerifyInfo {
	isOpen: boolean;
	openedFrom: "profile" | "voting";
}

interface initialState {
	userData: null | User;
	tokens: number | null;
	verifyWindow: IVerifyInfo;
	hasUserVerified: boolean;
	isJoinedToProjects: string[];
	auth: AuthInfo;
	openClaimTokensWindow: boolean;
	openJoinProjectWindow: boolean;
	openGuideWindow: boolean;
}

const initialState: initialState = {
	userData: null,
	tokens: null,
	auth: { openAuthModal: false, authStep: "basic", isOpenedFrom: null },
	openClaimTokensWindow: false,
	verifyWindow: { isOpen: false, openedFrom: "profile" },
	hasUserVerified: false,
	isJoinedToProjects: [],
	openJoinProjectWindow: false,
	openGuideWindow: false,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User | null>) => {
			state.userData = action.payload;
		},
		setTokens: (state, action: PayloadAction<number>) => {
			state.tokens = action.payload;
		},
		setVerifyWindow: (state, action: PayloadAction<IVerifyInfo>) => {
			state.verifyWindow = { ...state.verifyWindow, ...action.payload };
		},
		setHasUserVerified: (state, action: PayloadAction<boolean>) => {
			state.hasUserVerified = action.payload;
		},
		setIsJoinedToProjects: (
			state,
			action: PayloadAction<string | string[]>
		) => {
			if (Array.isArray(action.payload)) {
				state.isJoinedToProjects = action.payload;
				return;
			}
			if (state.isJoinedToProjects.includes(action.payload)) return;
			state.isJoinedToProjects.push(action.payload);
		},
		setAuth: (state, action: PayloadAction<AuthInfo>) => {
			state.auth = { ...state.auth, ...action.payload };
		},
		setOpenClaimTokensWindow: (state, action: PayloadAction<boolean>) => {
			state.openClaimTokensWindow = action.payload;
		},
		setOpenJoinProjectWindow: (state, action: PayloadAction<boolean>) => {
			state.openJoinProjectWindow = action.payload;
		},
		setOpenGuideWindow: (state, action: PayloadAction<boolean>) => {
			state.openGuideWindow = action.payload;
		},
	},
});

export const {
	setUser,
	setTokens,
	setVerifyWindow,
	setHasUserVerified,
	setIsJoinedToProjects,
	setAuth,
	setOpenClaimTokensWindow,
	setOpenJoinProjectWindow,
	setOpenGuideWindow,
} = userSlice.actions;
export default userSlice.reducer;
