import React, { FC } from "react";
import { ReactComponent as Edit } from "../../assets/profile/edit.svg";
import { ReactComponent as Verified } from "../../assets/profile/verified.svg";
import { AuthButton } from "../auth/AuthButton";
import { Link } from "react-router-dom";
import { paths } from "../../constants";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../redux/hooks";
import { setVerifyWindow } from "../../redux/api/userSlice";
import ProfileModalAbout from "./ProfileModalAbout";
import ProfileModalMap from "./ProfileModalMap";
import ProfileModalInterests from "./ProfileModalInterests";
import ProfileModalSidebar from "./ProfileModalSidebar";
import ProfileModalSpots from "./ProfileModalSpots";

interface IProps {
	randomAvatar?: string;
	displayName: string;
	userName: string;
	aboutUser: string;
	userInterests: string[] | null;
	favoriteSpot: {
		longitude: number;
		latitude: number;
		zoom: number;
	} | null;
	handleAvatarDropdownClick: () => void;
	isUserVerified: boolean;
}
const ProfileModal: FC<IProps> = ({
	randomAvatar,
	displayName,
	userName,
	aboutUser,
	userInterests,
	favoriteSpot,
	handleAvatarDropdownClick,
	isUserVerified,
}) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	const submittedSpotsMock = 0;
	const votedSpotsMock = 0;

	return (
		<div
			className={
				"w-[566px] h-[515px] relative rounded-xl border-solid border-2 overflow-clip  border-black bg-white "
			}
		>
			<div className={"flex sticky justify-between p-4 border-b-[1px]"}>
				<div className="flex">
					<div className={"text-base font-semibold font-poppins"}>
						{displayName}
					</div>
					<div
						className={"ml-1 text-base font-medium text-gray-400 font-poppins"}
					>
						@{userName}
					</div>
				</div>
				{/* <Link to={paths.verify}> */}
				{!isUserVerified && (
					<button
						onClick={() => {
							dispatch(
								setVerifyWindow({ isOpen: true, openedFrom: "profile" })
							);
							handleAvatarDropdownClick();
						}}
						className="flex cursor-pointer justify-center items-center rounded-full outline outline-2 outline-black py-1 px-2 gap-2"
					>
						<div className="flex items-center justify-center">
							<Verified className="fill-black w-4 h-4" />
						</div>
						<p className="text-sm">{t("editProfile.verify")}</p>
					</button>
				)}
				{/* </Link> */}
			</div>
			<div className={"flex justify-between "}>
				<ProfileModalSidebar />
				<div
					className={
						"relative flex flex-col justify-start h-[29rem] overflow-y-scroll items-center flex-1"
					}
				>
					<Link
						to={paths.editProfile}
						className="absolute cursor-pointer top-4 right-4"
					>
						<Edit />
					</Link>
					<div className="relative">
						<div
							className={
								"flex shrink-0 justify-center  h-[114px] w-[114px] mt-2 overflow-hidden rounded-full"
							}
						>
							<img
								className={"h-[114px] w-[114px]"}
								height={114}
								src={randomAvatar}
								alt="avatar"
							/>
						</div>
						<div className="group cursor-pointer">
							<Verified
								fill={isUserVerified ? "#1A1A1A" : "#C5C5C5"}
								className={"absolute bottom-1 right-1"}
							/>
							{isUserVerified && (
								<span className="absolute bottom-[-50px] right-[-60px] w-[131px] flex justify-center hidden group-hover:block bg-[#1A1A1A] p-[8px] text-[14px] text-white rounded-[4px] opacity-80">
									{t("verification.accountVerified")}
								</span>
							)}
						</div>
					</div>
					<ProfileModalSpots
						submitted={submittedSpotsMock}
						voted={votedSpotsMock}
					/>
					{aboutUser && (
						<ProfileModalAbout name={displayName} about={aboutUser} />
					)}
					{userInterests && !!userInterests.length && (
						<ProfileModalInterests interests={userInterests} />
					)}
					{favoriteSpot && <ProfileModalMap spot={favoriteSpot} />}
					<Link className="my-3" to={paths.editProfile}>
						<AuthButton
							text={
								!aboutUser || !favoriteSpot
									? t("profileModal.complete")
									: t("editProfile.title")
							}
							className="mb-3 !w-max px-16 border-0"
						/>
					</Link>
				</div>
			</div>
		</div>
	);
};

export { ProfileModal };
