import { useState } from "react";
import { useTranslation } from "react-i18next";

import { arrayUnion } from "firebase/firestore";
import DetailedViewTitle from "./DetailedViewTitle";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import {
	setOpenClaimTokensWindow,
	setIsJoinedToProjects,
} from "../redux/api/userSlice";
import useUserProfileInfo from "../hooks/useUserProfileInfo";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setTokens } from "../redux/api/userSlice";
import { Modal } from "./Modal";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import Lottie from "react-lottie";
import claimTokensPary from "../assets/detailedView/claimTokensPary.png";
import claimTokensParyDe from "../assets/detailedView/claimTokensParyDe.png";
import claimTokensIcon from "../assets/detailedView/claimTokensIcon.svg";
import claimTokensAnimation from "../assets/animation/tokensCompleted.json";

interface IClaimTokens {
	setIsClaimedTokens: (arg: boolean) => void;
	isOpen: boolean;
}

const ClaimTokensWindow = ({ setIsClaimedTokens, isOpen }: IClaimTokens) => {
	const { t, i18n } = useTranslation();
	const dispatch = useAppDispatch();

	const currentOpenedProject = useAppSelector(
		(state) => state.projectCards.selectedProjectId
	);

	const currentLanguage = i18n.language;

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: claimTokensAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const [startAnimation, setStartAnimation] = useState(false);
	const { user } = useGetCurrentUser();
	const userId = user?.uid;
	const { tokens } = useUserProfileInfo(`${userId}`);

	const claimTokens = async () => {
		if (!userId || !currentOpenedProject) return;
		try {
			const userRef = doc(db, "users", userId);
			const claimedTokens = tokens + 250;

			if (typeof tokens !== "undefined") {
				await updateDoc(userRef, {
					tokens: claimedTokens,
				});
				await updateDoc(userRef, {
					isJoinedToProjects: arrayUnion(currentOpenedProject),
				});
				dispatch(setTokens(claimedTokens));
			}

			setStartAnimation(true);
			dispatch(setIsJoinedToProjects(currentOpenedProject));

			setTimeout(() => {
				setIsClaimedTokens(true);
				dispatch(setOpenClaimTokensWindow(false));
			}, 2000);
		} catch (err) {
			console.error(err);
		}
	};

	return (
		<Modal
			modalContainerClassName={`relative max-w-[567px] max-h-[622px] h-full overflow-y-auto overflow-x-hidden rounded-[10px] bg-white p-[40px]`}
			isOpen={isOpen}
			handleClose={() => dispatch(setOpenClaimTokensWindow(false))}
			closeButtonHidden={true}
			contentContainerClassName={`flex self-center`}
		>
			<div className="flex items-center justify-center bg-white relative w-full h-full">
				<div className="max-w-[342px] w-full flex flex-col justify-center items-center gap-[52px]">
					<div className={`text-center ${startAnimation && "invisible"}`}>
						<p>{t("verification.projectWelcome")}</p>
						<DetailedViewTitle>Cycling Spots</DetailedViewTitle>
					</div>

					<div className="w-[206px] h-[206px] rounded-full shadow-2xl relative">
						<img
							className=""
							src={
								currentLanguage === "en" ? claimTokensPary : claimTokensParyDe
							}
							alt="Claim tokens pary"
						/>
					</div>

					<div className={`w-full ${startAnimation && "invisible"}`}>
						<button
							onClick={claimTokens}
							className="bg-black py-[16px] text-white rounded-[50px] w-full flex text-center items-center justify-center px-[30px] gap-[8px]"
						>
							<img src={claimTokensIcon} alt="tokens icon" />{" "}
							<span>{t("verification.claimTokensCollect")}</span>
						</button>
					</div>
				</div>
				{startAnimation && (
					<div className="absolute inset-0 flex items-center justify-center">
						<Lottie options={defaultOptions} />
					</div>
				)}
			</div>
		</Modal>
	);
};

export default ClaimTokensWindow;
