import { useState, useEffect, useCallback } from "react";
import { DocumentData } from "@firebase/firestore";

import AddPhoto from "./AddPhoto";
import AddInput from "./AddInput";
import TagsToPlace from "./TagsToPlace";
import AddVotes from "./AddVotes";
import AddDescription from "./AddDescription";
import DeletePlaceModal from "./DeletePlaceModal";
import RestrictionBanner from "./RestrictionBanner";
import AddPlaceHeader from "./AddPlaceHeader";
import SubmitPlaceButton from "./SubmitPlaceButton";
import AddCategories from "./AddCategories";

import { calcVotesPercantageByDistrict } from "../../../utils/calcVotesPercantage";
import { createVoteInfo } from "../../../utils/createVoteInfo";
import useSelectCreatedSpot from "../../../utils/selectCreatedSpot";

import useHandleAddSpotVote from "../../../hooks/useHandleAddSpotVote";
import { useGetCurrentUser } from "../../../hooks/useGetCurrentUser";
import useUserProfileInfo from "../../../hooks/useUserProfileInfo";
import useSetImage from "../../../hooks/useSetImage";
import useHandleAddSpot from "../../../hooks/useHandleAddSpot";

import { setCategorySelected } from "../../../redux/api/spotsSlice";
import { setAddPlace, setEditPlace } from "../../../redux/api/locationSlice";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useLazyGetAddressQuery } from "../../../redux/api/apiSlice";
import { setTokens } from "../../../redux/api/userSlice";

import triangle from "../../../assets/addplace/triangle.svg";

import NewCropper from "../../../shared/Cropper";

const AddPlace = () => {
	const dispatch = useAppDispatch();

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isCropImage, setIsCropImage] = useState(false);
	const [showBanner, setShowBanner] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [placeData, setPlaceData] = useState<DocumentData>({
		category: null,
		color: null,
		country: null,
		date: null,
		description: "",
		district: null,
		districtRank: null,
		latitude: null,
		level: null,
		longitude: null,
		percantage: null,
		picture: null,
		region: null,
		spotColor: null,
		streetName: "",
		submitted_by: null,
		time: null,
		total_votes: 1,
		type: null,
		secondaryTags: [],
		votes: {},
	});

	const editPlace = useAppSelector((state) => state.location.editPlace);
	const selectedCard = useAppSelector((state) => state.spots.selectedCard);
	const addPlaceCoordinates = useAppSelector(
		(state) => state.location.addPlace
	);
	const userCurrTokens = useAppSelector((state) => state.user.tokens);
	const hasUserVerified = useAppSelector((state) => state.user.hasUserVerified);
	const allSpots = useAppSelector((state) => state.spots.spotsData);

	const { user } = useGetCurrentUser();
	const userID = user?.uid;
	const { tokens } = useUserProfileInfo(`${userID}`, false);

	const [getAddress, { isFetching }] = useLazyGetAddressQuery();
	const { onSubmitHandler } = useSelectCreatedSpot();
	const { handleSubmit, handleUpdate, handleDelete } = useHandleAddSpot();
	const { handleVotesChange } = useHandleAddSpotVote();

	const {
		placeImage,
		handlePlacePhotoChange,
		handleSizeError,
		handleRemoveImage,
		setPlaceImage,
	} = useSetImage();

	useEffect(() => {
		if (editPlace && selectedCard) {
			const newObj = structuredClone(selectedCard);
			const newArr = [newObj.type, ...newObj.secondaryTags];
			setPlaceData({
				...newObj,
				secondaryTags: newArr,
				type: null,
			});

			setPlaceImage((placeImage) => ({
				...placeImage,
				placeUrl: newObj.picture,
			}));
		}
	}, [editPlace, selectedCard]);

	useEffect(() => {
		if (addPlaceCoordinates) {
			if (userID && tokens) {
				setPlaceData((prevData) => ({
					...prevData,
					longitude: addPlaceCoordinates[1],
					latitude: addPlaceCoordinates[0],
					submitted_by: userID,
					total_votes: 1,
					votes: {
						...prevData.votes,
						[userID]: createVoteInfo(userID),
					},
				}));
				handleSetTokens();
			}
			const getQueryAddress = async () => {
				const { data } = await getAddress(addPlaceCoordinates.join(","));

				if (data) {
					const fullAddress = `${
						data.address.road ||
						data.address.state_district ||
						data.address.state ||
						data.address.municipality
					}, ${
						(data.address.house_number && data.address.house_number + ",") || ""
					} ${
						data.address.suburb ||
						data.address.village ||
						data.address.town ||
						data.address.county ||
						data.address.neighbourhood ||
						data.address.borough ||
						data.address.tourism
					}, ${data.address.postcode}`;
					const district =
						data.address.borough ||
						data.address.state_district ||
						data.address.county ||
						data.address.state ||
						"";
					const country = data.address.country || "";
					const region = data.address.city || data.address.state || "";

					setPlaceData((prevData) => ({
						...prevData,
						streetName: fullAddress,
						district: district,
						country: country,
						region: region,
					}));
				}
			};
			getQueryAddress();
		}
	}, [addPlaceCoordinates, userID, tokens]);

	useEffect(() => {
		if (placeData.district !== null && placeData.category !== null) {
			const getDistrictPercents = async () => {
				const distrSpots: DocumentData[] = [];
				allSpots.forEach((spot: DocumentData) => {
					if (spot.district === placeData.district) {
						distrSpots.push(JSON.parse(JSON.stringify(spot)));
					}
				});
				const votes = calcVotesPercantageByDistrict(
					distrSpots,
					placeData.district,
					placeData
				);
				const updatedSpot =
					editPlace && selectedCard
						? votes.find((spot) => spot.id === selectedCard.id)
						: votes.find((spot) => !spot.id);
				setPlaceData((prevData) => ({
					...prevData,
					percantage: updatedSpot?.percantage,
					districtRank: updatedSpot?.districtRank,
					color: updatedSpot?.color,
					level: updatedSpot?.level,
				}));
			};
			getDistrictPercents();
		}
	}, [placeData.category, placeData.district, placeData.total_votes]);

	const handleSetTokens = useCallback(() => {
		dispatch(setTokens(tokens - 1));
	}, [dispatch, tokens]);

	const handleCategory = (category: string) => {
		setPlaceData((prevData) => ({
			...prevData,
			category: category,
		}));
		dispatch(setCategorySelected(true));
	};

	const handleStreetName = (value: string) => {
		setPlaceData((prevData) => ({
			...prevData,
			streetName: value,
		}));
	};

	const handleClose = () => {
		dispatch(setAddPlace(null));
		dispatch(setEditPlace(false));
		dispatch(setCategorySelected(false));
	};

	const hangeChangeTags = (arr: string[]) => {
		setPlaceData((prevData) => ({
			...prevData,
			secondaryTags: arr,
		}));
	};

	const handleChangeDescription = (str: string) => {
		setPlaceData((prevData) => ({
			...prevData,
			description: str,
		}));
	};

	const handleVotes = (direction: string) => {
		handleVotesChange({
			direction,
			hasUserVerified,
			userID,
			tokens,
			userCurrTokens,
			placeData,
			setShowBanner,
			setPlaceData,
		});
	};

	return (
		<div className="relative">
			{showBanner && <RestrictionBanner />}
			<form
				onSubmit={(e) => {
					e.preventDefault();
					editPlace
						? handleUpdate({
								userID,
								setIsLoading,
								placeData,
								tokens,
								selectedCard,
								placeFile: placeImage.placeFile,
								handleClose,
								onSubmitHandler,
						  })
						: handleSubmit({
								userID,
								setIsLoading,
								placeData,
								tokens,
								userCurrTokens,
								placeFile: placeImage.placeFile,
								handleClose,
								onSubmitHandler,
						  });
				}}
				className="w-[690px] h-full bg-[#F0F0F0] px-4 pb-4 rounded-[20px] relative overflow-auto"
			>
				<AddPlaceHeader
					editPlace={editPlace}
					handleClose={handleClose}
					tokens={tokens}
				/>
				<AddCategories
					handleCategory={handleCategory}
					placeDataCategory={placeData.category}
				/>
				{placeData.category && (
					<>
						<AddInput
							strValue={placeData.streetName}
							handleChange={handleStreetName}
							isFetching={isFetching}
						/>

						<AddPhoto
							placeImage={placeImage}
							handleRemoveImage={handleRemoveImage}
							handlePlacePhotoChange={handlePlacePhotoChange}
							handleSizeError={handleSizeError}
							setImageCrop={setIsCropImage}
						/>

						<TagsToPlace
							category={placeData.category}
							handleChange={hangeChangeTags}
							addedTags={editPlace ? placeData.secondaryTags : []}
						/>

						<AddVotes
							votes={placeData.total_votes}
							percantage={placeData.percantage}
							color={placeData.color}
							handleChange={handleVotes}
							voteInfo={placeData.votes[userID as string]}
							category={placeData.category}
						/>

						<AddDescription
							value={placeData.description}
							handleChange={handleChangeDescription}
						/>
						<SubmitPlaceButton
							category={placeData.category}
							district={placeData.district}
							isFetching={isFetching}
							isLoading={isLoading}
							editPlace={editPlace}
							secondaryTags={placeData.secondaryTags.length === 0}
							placeUrl={placeImage.placeUrl}
							setIsOpen={setIsOpen}
						/>
					</>
				)}
			</form>
			<div className="absolute left-[-25px] top-[0] z-[-1]">
				<img src={triangle} alt="decor" />
			</div>
			<DeletePlaceModal
				isOpen={isOpen}
				handleClose={() => setIsOpen(false)}
				handleDelete={() =>
					handleDelete({ userID, placeData, tokens, handleClose })
				}
			/>
			{placeImage.placeFile && placeImage.placeUrl && (
				<NewCropper
					imgURL={placeImage.placeUrl}
					saveChanges={handlePlacePhotoChange}
					isOpen={isCropImage}
					handleClose={() => setIsCropImage(false)}
				/>
			)}
		</div>
	);
};

export default AddPlace;
